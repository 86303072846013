<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/risk/List"
        :params="params"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        :header="header"
        :footer="footer"
        @on-ok="$emit('on-ok',$event)"
        :multiColumn="multiColumn"
        :detailInner="detailInner"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="风险级别" prop="level">
          <Select v-model="params.level" clearable placeholder="风险级别" style="width: 100px;">
            <Option v-for="(item,index) in baseData['风险级别']" :style="{'color':item.color }" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
      </template>
      <template slot="btn" v-if="amendId<=0">
        <ButtonGroup>
          <Button type="primary" @click="add" :disabled="midData.length===1&&(midData[0].status!==5||!!midData[0].amendPublishedAt)" v-if="mid && !assembly">新增</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :mid="detailID" :checkType="checkType"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" :title="(tableSelectItem.length>0?'修改':'新增')+'风险'">
      <Edit @on-ok="getList" :parentID="mid" :midData="midData.length > 0 ? midData : tableSelectItem" :mid="detailID"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import Edit from "@/views/service/identification/risk/Edit.vue";
import Detail from "@/views/service/identification/risk/detail.vue";

export default {
  name: 'problems',
  mixins: [pageList],
  props: {
    assembly: {type: Boolean, default: false}, // 是否作为组件来使用
    type: {type: String, default: ''},
    amendId: {type: [Number, String], default: null},
    projectId: {type: [Number, String], default: null},
    categoryId: {type: [Number, String], default: null},
    dictId: {type: [Number, String], default: null},
    itemId: {type: [Number, String], default: null},
    amendType: {type: [Number], default: null},
    amendStatus: {type: [Number], default: null},
    status: {type: [Number], default: null},
    riskGrade: {type: [Number], default: null},
    checkType: {type: Number, default: 2}, // 任务类型
  },
  components: {
    Edit,
    Detail,
  },
  data() {
    return {
      checkFlag: false,
      params: {
        checkId: '',
        level: '',
        type: '',
        keyword: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 100, title: '检查专家', key: 'expertName', align: 'center'},
        {width: 170, title: '发现时间', key: 'serveTime', align: 'center'},
        {width: 100, title: '风险级别', key: 'level', align: 'center', renderConfig: {type: 'baseData', parentName: '风险级别'}},
        {minWidth: 230, title: '风险描述', tooltip: true, key: 'desc', align: 'left'},
        {minWidth: 230, title: '防范措施', tooltip: true, key: 'measure', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      if (this.mid && !this.assembly) {
        return [
          {
            hiddenBtnIcon: true,
            children: [
              {
                type: 'detail',
                title: '查看详情',
                icon: 'md-eye',
                disabled: this.tableSelectItem.length !== 1
              },
              {
                click: () => this.modShow = true,
                title: '编辑',
                icon: 'md-create',
                iconColor: '#19be6b',
                disabled: this.tableSelectItem.length !== 1 || (this.midData && this.midData.length === 1 && this.midData[0].status !== 5) || this.tableSelectItem[0].amendStatus === 2
              },
              {
                click: () => this.delItem('/risk/Remove'),
                iconColor: '#ed4014',
                title: '删除',
                icon: 'md-trash',
                disabled: this.tableSelectItem.length !== 1 || (this.midData && this.midData.length === 1 && this.midData[0].status !== 5) || this.tableSelectItem[0].amendStatus === 2
              },
            ]
          }
        ]
      } else {
        // 非组件时，列表中数据不全(有些数据只有任务才有)暂时先去除编辑和删除功能
        return [
          {
            hiddenBtnIcon: true,
            children: [
              {
                type: 'detail',
                title: '查看详情',
                icon: 'md-eye',
                disabled: this.tableSelectItem.length !== 1
              }
            ]
          }
        ]
      }
    }
  },
  mounted() {
    this.params.checkId = this.mid || '';
    this.params.type = this.type || '';
    this.params.amendId = this.amendId || '';
    this.params.projectId = this.projectId || '';
    this.params.categoryId = this.categoryId || '';
    this.params.dictId = this.dictId || '';
    this.params.itemId = this.itemId || '';
    this.params.amendType = this.amendType || '';
    this.params.amendStatus = this.amendStatus || '';
    this.params.status = this.status || '';
    this.params.riskGrade = this.riskGrade || '';
    this.getList()
  },
  methods: {
    // deteChange(date) {
    //   this.params.UpdatedStartAt = date[0]
    //   this.params.UpdatedEndAt = date[1]
    //   this.params.status = 2
    // },
  }
}
</script>
