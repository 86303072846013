<template>
  <ys-detail :icon="detailIcon" :loading="loading" @on-error="getDetail" name="">
    <Tag size="medium" color="blue" slot="status">{{ detail.level|getBaseDataName('风险级别') }}</Tag>
    <template slot="brief">
      <ys-cell title="发现时间" :label="detail.serveTime|formatDate"/>
      <ys-cell title="检查专家" :label="detail.expertName"/>
      <ys-cell title="风险描述" :label="detail.desc"/>
    </template>
    <template slot="btn">

    </template>
    <template slot="main-left">
      <h1 class="h1Title">详细信息</h1>
      <div :style="{maxHeight:detailHeight+'px'}" style="overflow-y: auto">
        <ys-cell-group :gutter="2">
          <ys-cell title="风险级别" :label="detail.level|getBaseDataName('风险级别')"/>
          <ys-cell title="隐患类型" v-if="checkType === 9" :label="detail.type|getBaseDataName('隐患类型')"/>
          <ys-cell title="发现时间" :label="detail.serveTime|formatDate"/>
          <ys-cell title="风险描述" :label="detail.desc" style="width: 100%" block/>
          <ys-cell title="整改建议" :label="detail.advice" style="width: 100%" block/>
          <ys-cell title="防范措施" :label="detail.measure" style="width: 100%" block/>
          <ys-cell title="检查专家" :label="detail.expertName"/>
          <ys-cell title="提交时间" :label="detail.createdAt"/>
        </ys-cell-group>
        <h1 class="h1Title">检查附件</h1>
        <div v-viewer="{url: 'data-src'}">
          <ys-image
              v-for="subItem in (detail.fileList||[])"
              :key="subItem.id"
              :src="config.baseURL + '/resource/GetFile/'+subItem.id+'?size=100'"
              :data-src="config.baseURL + '/resource/GetFile/'+subItem.id"
              :width="100" :height="100"/>
        </div>
      </div>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">暂未开放</h1>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {getRiskInfo} from "@/api/common";

export default {
  name: "detail",
  mixins: [detailMixins],
  props: {
    checkType: {type: Number, default: 2}, // 任务类型
  },
  data() {
    return {
      amendDetail: {},
      loading: true
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      getRiskInfo({id: this.mid}).then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
          this.setTagName(this.detail.iname);
        }
      }).catch(() => {
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>