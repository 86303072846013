<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="90" :style="{height:height-200+'px',overflowY:'auto'}">
      <FormItem label="风险级别" prop="level">
        <Select v-model="form.level" placeholder="风险级别">
          <Option v-for="(item,index) in baseData['风险级别']" :style="{'color':item.color }" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="隐患类型" prop="type">
        <Select v-model="form.type" clearable placeholder="隐患类型">
          <Option v-for="item in baseData['隐患类型']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="风险描述" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" :autosize="{minRows: 2,maxRows: 5}" placeholder="风险描述"></Input>
      </FormItem>
      <FormItem label="防范措施" prop="measure" style="width: 100%">
        <Input v-model="form.measure" type="textarea" :autosize="{minRows: 2,maxRows: 5}" placeholder="防范措施"></Input>
      </FormItem>
      <FormItem label="整改建议" prop="advice" style="width: 100%">
        <Input v-model="form.advice" type="textarea" :autosize="{minRows: 2,maxRows: 5}" placeholder="整改建议"></Input>
      </FormItem>
      <FormItem label="发现时间" prop="serveTime" style="width: 100%">
        <ys-date-picker type="datetime" v-model="form.serveTime" style="width: 100%;" placeholder="风险发现时间"></ys-date-picker>
      </FormItem>
      <FormItem label="附件" prop="fileList" style="width: 100%">
        <ys-upload :format="['jpg','jpeg','png','gif']" :headers="{token:token}" action="/web/resource/UploadFile" :defaultList.sync="fileList" type="halfList" @on-success="bLFile" :limit="0"></ys-upload>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(form.id ? '/risk/Edit' : '/risk/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import edit from '@/mixins/edit';
import {getRiskInfo} from "@/api/common";

export default {
  name: 'problemAdd',
  components: {},
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        desc: [
          {type: 'string', required: true, message: '风险描述不能为空', trigger: 'blur'}
        ],
        measure: [
          {type: 'string', required: true, message: '防范措施不能为空', trigger: 'blur'}
        ],
      },
      fileList: [],
      form: {
        checkId: '',
        id: '',
        type: 'JCGL6',
        level: 2,
        advice: '',
        measure: '',
        desc: '',
        serveTime: new Date,
        fileList: [],
      },
    }
  },

  async mounted() {
    this.form.checkId = this.parentID;
    if (this.mid) {
      await this.getDetail()
    }
  },
  methods: {
    async getDetail() {
      await getRiskInfo({id: this.mid}).then((res) => {
        if (res.code === 200) {
          Object.keys(this.form).forEach((item) => {
            this.form[item] = res.data[item]
          })
          this.fileList = res.data.fileList || [];
          this.form.fileList = this.fileList.map((item) => item.id);
        }
      })
    },

    bLFile(data) {
      this.form.fileList = data.map((item) => {
        return item.id
      });
    },
  }

}
</script>
<style scoped lang="less">

</style>